// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyCz8uwySRi7b4cG9a0MsC5sEB7rmsPxe4o",
  authDomain: "axcent-866d5.firebaseapp.com",
  projectId: "axcent-866d5",
  storageBucket: "axcent-866d5.appspot.com",
  messagingSenderId: "83436438841",
  appId: "1:83436438841:web:067041fdaef6515ee914e8",
  measurementId: "G-RJ7LKWMW66"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;

export { firebaseApp };
