import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import styles from './settings.module.css'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import { SolarSystemInfoCard } from '../../components/DataCards/DataCards'

const Settings = () => {
  const [value, setValue] = React.useState(0);

  //Handlers-------------------------------------
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  function handleSubmit(event) {
    event.preventDefault();

    console.log('Form submitted');
  }

  const solarSystems = [
    { id: 1, name: "Hotel Kimberly", capacity: "5 kW", location: "Rooftop", status: "Active" },
    { id: 2, name: "10 Banaba Circle", capacity: "10 kW", location: "Field", status: "Under Maintenance" },
    // ... more systems ...
  ];

  return (
    <Box sx={{ width: '100%', minHeight: '100vh' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="settings tabs"
        sx={{
          '.MuiTab-root': {
            textTransform: 'none',
            '&::first-letter': {
              textTransform: 'uppercase',
            },
            // Override default hover effect
            '&:hover': {
              backgroundColor: 'transparent',
            },
            // Padding and margin adjustments
            padding: 0,
            marginRight: '25px',
            minWidth: '0px',
          },
        }}
      >
        <Tab label="Account" disableRipple />
        <Tab label="System Management" disableRipple />
        <Tab label="Energy Price" disableRipple />
      </Tabs>
      <Box sx={{ marginTop: '25px' }}>
        {value === 0 &&
          <div className={styles.container}>
            <form className={styles.form}>
              <h5 className={styles.sectionTitle}>Name</h5>
              <TextField
                size='small'
                label="First"
                id="outlined-start-adornment"
                sx={{ margin: '5px', width: '100%', maxWidth: '300px' }}
              />

              <br></br>
              <TextField

                size='small'
                label="Last"
                id="outlined-start-adornment"
                sx={{ margin: '5px', width: '100%', maxWidth: '300px' }}
              />

              <br></br>
              <Button variant="outlined" sx={{ margin: '5px', textTransform: 'none' }}>Save</Button>


              <h5 className={styles.sectionTitle}>Account</h5>

              <Box sx={{
                display: 'flex',
              }}>
                <TextField
                  label="Email"
                  size='small'
                  id="outlined-start-adornment"
                  sx={{ margin: '5px', width: '100%', maxWidth: '300px' }}
                  disabled='true'
                  defaultValue="fdsfdsa"
                />
                <br />
                <Button variant="text" sx={{ margin: '5px', textTransform: 'none', }}>Change</Button>
              </Box>

              <Box sx={{
                display: 'flex',
              }}>

                <TextField
                  label="Phone Number"
                  size='small'
                  id="outlined-start-adornment"
                  sx={{ margin: '15px 5px', width: '100%', maxWidth: '300px' }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">+63</InputAdornment>,
                  }}
                  disabled='true'
                />
                <br></br>
                <Button variant="text" sx={{ margin: '5px', textTransform: 'none' }}>Change</Button>
              </Box>
              <h5>Password</h5>
              <Button variant="outlined" sx={{ margin: '5px', textTransform: 'none' }}>Change Password</Button>
            </form>
          </div>
        }

        {value === 1 &&
          <div style={{
            marginTop: '25px',
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
            gap: '15px'
          }}>
            {solarSystems.map(system => (
              <SolarSystemInfoCard key={system.id} system={system}></SolarSystemInfoCard>
            ))}
          </div>
        }

        {value === 2 &&
          <div>
            <h1>Input Energy Data</h1>
            <form onSubmit={handleSubmit}>
              <label>
                Customer Type:
                <select name="customerType">
                  <option value="Residential 0-20">Residential 0 to 20 kWh</option>
                  <option value="Residential 21-50">Residential 21 to 50 kWh</option>
                  <option value="Residential 51-70">Residential 51 to 70 kWh</option>
                  <option value="Residential 71-100">Residential 71 to 100 kWh</option>
                  <option value="Residential 101-200">Residential 101 to 200 kWh</option>
                  <option value="Residential 201-300">Residential 201 to 300 kWh</option>
                  <option value="Residential 301-400">Residential 301 to 400 kWh</option>
                  <option value="Residential over400">Residential Over 400 kWh</option>
                  <option value="General Service A 0-200">General Service A 0 to 200 kWh</option>
                  <option value="General Service A 201-300">General Service A 201 to 300 kWh</option>
                  <option value="General Service A 301-400">General Service A 301 to 400 kWh</option>
                  <option value="General Service A over400">General Service A Over 400 kWh</option>
                  <option value="General Service B">General Service B</option>
                  <option value="General Power (GP) Secondary - Medium">General Power (GP) Secondary - Medium</option>
                  <option value="General Power (GP) Secondary - Large">General Power (GP) Secondary - Large</option>
                  <option value="General Power (GP) Secondary - Very Large">General Power (GP) Secondary - Very Large</option>
                </select>
              </label>
              <div>
                <label>
                  Date:
                  <input type="month" name="date" defaultValue={new Date().toISOString().slice(0, 7)} />
                </label>
              </div>

              <div>
                <h2>Generation Charge</h2>
                <label>Per kWh: <input type="number" /></label>
              </div>
              <div>
                <h2>Transmission Charge</h2>
                <label>Per kWh: <input type="number" /></label>
                <label>Per kW: <input type="number" /></label>
              </div>
              <div>
                <h2>Distribution Group</h2>
                <p>Distribution Charge</p>
                <label>Per kWh: <input type="number" /></label>
                <label>Per kW: <input type="number" /></label>
                <p>Supply Charge</p>
                <label>Per kWh: <input type="number" /></label>
                <label>Per customer/month: <input type="number" /></label>
                <p>Metering Charge</p>
                <label>Per kWh: <input type="number" /></label>
                <label>Per customer/month: <input type="number" /></label>
              </div>
              <div>
                <h2>System Loss Charge</h2>
                <label>Per kWh: <input type="number" /></label>
              </div>
              <div>
                <h2>Lifeline Subsidy</h2>
                <label>kWh: <input type="number" /></label>
              </div>
              <div>
                <h2>Senior Citizen Subsidy</h2>
                <label>kWh: <input type="number" defaultValue="0.0001"/></label>
              </div>
              <div>
                <h2>Current RPT Charge</h2>
                <label>Per kWh: <input type="number" /></label>
              </div>
              <div>
                <h2>Universal Charge Group</h2>
                <p>UC-ME for NPC-SPUG</p>
                <label>Per kWh: <input type="number" defaultValue="0.2238" /></label>
                <p>UC-ME for RED</p>
                <label>Per kWh: <input type="number" defaultValue="0.0017" /></label>
                <p>UC-ET</p>
                <label>Per kWh: <input type="number" defaultValue="0.0000" /></label>
                <p>UC-SL</p>
                <label>Per kWh: <input type="number" defaultValue="0.0428" /></label>
              </div>
              <div>
                <h2>Fit-All (Renewable)</h2>
                <label>Per kWh: <input type="number" defaultValue="0.0364" /></label>
              </div>
              <div>
                <h2>Taxes</h2>
                <label>Generation: <input type="number" defaultValue="0.1135" /></label>
                <label>Transmission: <input type="number"  defaultValue="0.1070"/></label>
                <label>System Loss: <input type="number"  defaultValue="0.1129"/></label>
                <label>Other Charges: <input type="number"  defaultValue="0.12"/></label>
              </div>

              <button type="submit">Submit</button>
            </form>
          </div>
        }
      </Box>
    </Box>
  );
};

export default Settings;
