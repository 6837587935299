import React from 'react';
import { Outlet } from 'react-router-dom';
import NavBar from '../components/NavBar/NavBar';
import ToolBar from '../components/ToolBar/ToolBar';
import { Box } from '@mui/joy';
import { useLocation } from 'react-router-dom';

const Layout = ({ isNavOpen, toggleNav }) => {

  const location = useLocation();
  
  // Function to map pathname to page name
  const getPageName = (pathname) => {
    switch(pathname) {
      case '/':
        return 'Dashboard';
      case '/settings':
        return 'Settings';
      // Add more cases as needed
      default:
        return 'Page'; // Default page name
    }
  };

  const pageName = getPageName(location.pathname);

  return (
    <div>
      <NavBar isNavOpen={isNavOpen} toggleNav={toggleNav} />
      <Box sx={{
        marginLeft: '225px',
        padding: '25px',
        '@media (max-width: 700px)': {
          marginLeft: '0px !important',
        },
      }}>
        <ToolBar toggleNav={toggleNav} page={pageName} />
        <Outlet /> {/* This is where the nested routes will be rendered */}
      </Box>
    </div>
  );
};

export default Layout;
