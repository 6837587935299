import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './assets/library/protected-route';

import Layout from './assets/doms/layout/layout';
import Login from './assets/doms/layout/login';
import Dashboard from './assets/doms/pages/Dashboard/dashboard';
import Settings from './assets/doms/pages/Settings/settings';

import { UserProvider } from './assets/doms/context/UserContext';
import { SystemProvider } from './assets/doms/context/SystemContext';

function App() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <UserProvider>
      <SystemProvider>
        <Router>
          <div className="App">
            <Routes>
              <Route path="/" element={<Layout isNavOpen={isNavOpen} toggleNav={toggleNav} />}>
                <Route index element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                <Route path="settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
              </Route>
              <Route path="/login" element={<Login />} />
            </Routes>
          </div>
        </Router>
      </SystemProvider>
    </UserProvider>
  );
}

export default App;
