export const convertPower = (power, inputUnit = '', outputUnit = '', decimals = 0) => {

  if (typeof power !== "number" || isNaN(power)) {
    return NaN;
  }

  // Convert input power to base unit (Watts)
  switch (inputUnit.toLowerCase()) {
    case 'kw': // Convert from Kilowatts
      power *= 1000;
      break;
    case 'mw': // Convert from Megawatts
      power *= 1e6;
      break;
    case 'w': // No unit conversion, just formatting
      break;
    default:
      throw new Error("Invalid input unit for conversion");
  }

  let convertedValue;

  // Convert to output unit
  switch (outputUnit.toLowerCase()) {
    case 'kw': // Convert to Kilowatts
      convertedValue = power / 1000;
      break;
    case 'mw': // Convert to Megawatts
      convertedValue = power / 1e6;
      break;
    case 'w': // No unit conversion, just formatting
      convertedValue = power;
      break;
    default:
      throw new Error("Invalid output unit for conversion");
  }

  // Format the number with specified decimals
  return Number(convertedValue.toFixed(decimals));
};