import React from 'react';
import { Navigate } from 'react-router-dom';
import { firebaseApp } from '../library/firebase';
import { getAuth } from "firebase/auth";

const ProtectedRoute = ({ children }) => {

  const auth = getAuth(firebaseApp);

  const user = auth.currentUser; // Checks the current user

  if (!user) {
    // If no user, redirect to login
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;