// ToolBar.js

import React from 'react';
import styles from '../ToolBar/Toolbar.module.css'

const ToolBar = ({ toggleNav, page }) => {
  return (
    <div className={styles.toolBar}>
      <button className={styles.navToggle} onClick={toggleNav}>
        ≡
      </button>
      <h1>{page}</h1>
    </div>
  );
};

export default ToolBar;
