import { getFirestore, doc, getDoc, collection, query, where, getDocs, onSnapshot } from 'firebase/firestore';
import { firebaseApp } from '../library/firebase'; // Import the Firebase config
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";

const firestore = getFirestore(firebaseApp);

//Plants--------------------------------------------
export async function getPlantDocument(plantId) {
    try {
        const docRef = doc(firestore, 'plants', plantId);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
            const plantDocument = docSnapshot.data();
            return {
                ...plantDocument,
                id: plantId // Include the plantId
            };
        }
        return null; // No document found
    } catch (error) {
        console.error('Error getting plant document:', error);
        throw error;
    }
}

//Devices--------------------------------------------

export async function getAllDevicesInPlant(plantId) {
    try {
        const q = query(collection(firestore, 'devices'), where('plantId', '==', plantId));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            return querySnapshot.docs.map(doc => ({ documentId: doc.id, ...doc.data() }));
        }
        return null; // No documents found
    } catch (error) {
        console.error('Error getting devices:', error);
        throw error;
    }

}

//Monitoring--------------------------------------------
export function getLiveGridMonitoringData(plantId, date, onDataReceived, onError) {
    const startOfDate = new Date(date);
    startOfDate.setHours(0, 0, 0, 0);

    const endOfDate = new Date(date);
    endOfDate.setHours(23, 59, 59, 999);

    const q = query(
        collection(firestore, 'monitor_grid'),
        where('plantId', '==', plantId),
        where('date', '>=', startOfDate),
        where('date', '<=', endOfDate)
    );

    return onSnapshot(q, (querySnapshot) => {
        if (!querySnapshot.empty) {
            const doc = querySnapshot.docs[0];
            onDataReceived({ documentId: doc.id, power: doc.data().power });
        } else {
            onDataReceived(null); // No documents found
        }
    }, onError);
}


export function getLiveInverterMonitoringData(deviceId, date, onDataReceived, onError) {
    const startOfToday = new Date(date);
    startOfToday.setHours(0, 0, 0, 0);

    const endOfToday = new Date(date);
    endOfToday.setHours(23, 59, 59, 999);

    const q = query(
        collection(firestore, 'monitor_inverter'),
        where('deviceId', '==', deviceId),
        where('date', '>=', startOfToday),
        where('date', '<=', endOfToday)
    );

    return onSnapshot(q, (querySnapshot) => {
        if (!querySnapshot.empty) {
            const doc = querySnapshot.docs[0];
            onDataReceived({ documentId: deviceId, power: doc.data().power });
        } else {
            onDataReceived(null); // No documents found
        }
    }, onError);
}

export async function getGridDayMonitoringData(plantId, date) {
    const startOfDate = new Date(date);
    startOfDate.setHours(0, 0, 0, 0);

    const endOfDate = new Date(date);
    endOfDate.setHours(23, 59, 59, 999);

    const q = query(
        collection(firestore, 'monitor_grid'),
        where('plantId', '==', plantId),
        where('date', '>=', startOfDate),
        where('date', '<=', endOfDate)
    );

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs[0]?.data();
}

export async function getInverterDayMonitoringData(deviceId, date) {
    const startOfToday = new Date(date);
    startOfToday.setHours(0, 0, 0, 0);

    const endOfToday = new Date(date);
    endOfToday.setHours(23, 59, 59, 999);

    const q = query(
        collection(firestore, 'monitor_inverter'),
        where('deviceId', '==', deviceId),
        where('date', '>=', startOfToday),
        where('date', '<=', endOfToday)
    );

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs[0]?.data();
}

export async function getInverterMonthlyData(deviceId, date) {
    const startOfMonth = new Date(date);
    startOfMonth.setDate(1);
    startOfMonth.setUTCHours(0, 0, 0, 0);

    // Calculate end of the month
    const endOfMonth = new Date(startOfMonth);
    endOfMonth.setMonth(startOfMonth.getMonth() + 1);
    endOfMonth.setDate(0); // Automatically adjusts to the last day of the given month
    endOfMonth.setUTCHours(23, 59, 59, 999);

    const q = query(
        collection(firestore, 'monitor_inverter_month'),
        where('deviceId', '==', deviceId),
        where('date', '>=', startOfMonth),
        where('date', '<=', endOfMonth)
    );

    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
        console.log(querySnapshot.docs[0].data().date);
        return querySnapshot.docs[0].data();
    } else {
        return null;
    }
}

export async function getGridMonthlyData(plantId, date) {
    const startOfMonth = new Date(date);
    startOfMonth.setDate(1);
    startOfMonth.setHours(0, 0, 0, 0);

    const q = query(
        collection(firestore, 'monitor_grid_month'),
        where('plantId', '==', plantId),
        where('date', '==', startOfMonth),
    );

    try {
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            return querySnapshot.docs[0].data();
        } else {
            return null;
        }
    } catch (error) {
        console.log(error);
    }

}


//Users-------------------------------------------- 
export async function getUserData(userId) {
    try {
        const userRef = doc(firestore, 'users', userId);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
            return userSnap.data();
        } else {
            console.log('No such user!');
            return null;
        }
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}

//Images--------------------------------------------

export async function getPlantImageURL(plantId) {
    const storage = getStorage();
    const plantImagesRef = ref(storage, 'plantImages/' + plantId);

    var images = [];
    await listAll(plantImagesRef)
        .then((res) => {
            console.log(res.items);
            const urlPromises = res.items
                .filter(itemRef => itemRef.name.toLowerCase().endsWith('.jpg') || itemRef.name.toLowerCase().endsWith('.jpeg') || itemRef.name.toLowerCase().endsWith('.png'))
                .map(itemRef => getDownloadURL(itemRef));

            return Promise.all(urlPromises);
        })
        .then((urls) => {
            images = urls;
        })
        .catch((error) => {
            // Handle any errors
            console.error(error);
        });


    return images;
}