// src/components/Login.js

import React, { useState, useEffect } from 'react';
import { firebaseApp } from '../../library/firebase';
import { getAuth } from "firebase/auth";
import logo from '../../images/logo192.png';
import { signInWithEmailAndPassword } from "firebase/auth";
import { Box, Input, Button } from '@mui/joy';
import Snackbar from '@mui/joy/Snackbar';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';


const Login = () => {


  const auth = getAuth(firebaseApp);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // New state for loading

  const navigate = useNavigate();

  // Check if the user is already logged in
  useEffect(() => {
    setIsLoading(true); // Start loading
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        // User is signed in, navigate to the dashboard or home page
        navigate('/');
      }
    });

    setIsLoading(false); // Start loading
    return unsubscribe;
  }, [navigate]);

  const signIn = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading
    try {
      await signInWithEmailAndPassword(auth, email, password);
      // Navigate to the dashboard or desired page
      navigate('/');
    } catch (error) {
      let userFriendlyMessage = '';
      setIsLoading(false); // Stop loading on error
      if (error.code === 'auth/invalid-login-credentials') {
        userFriendlyMessage = 'Invalid email or password. Please try again.';
      } else {
        userFriendlyMessage = 'An unexpected error occurred. Please try again later.';

      }
      setSnackbarMessage(userFriendlyMessage);
      setSnackbarOpen(true);
    }

  };



  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
      maxHeight: '100vh', height: '100vh',
    }}>
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        color='danger'
        variant='outlined'
        autoHideDuration={3000} // Adjust duration as needed
      >
        {snackbarMessage}
      </Snackbar>

      <img
        src={logo} // Replace with your image path
        alt="Blueshift"
        width="100" // Set your desired width
        height="100" // Set your desired height
        style={{ marginBottom: '20px' }} // Adjust spacing as needed
      />
      <h4 style={{ margin: '0 0 10px 0' }}>Blueshift</h4>
      <p style={{ margin: '0 0 50px 0' }}>One account for everything with us.</p>

      <Box component="form" onSubmit={signIn} sx={{ width: '100%', maxWidth: 360 }}>
        <Input
          placeholder="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ marginBottom: '20px' }}
        />
        <Input
          placeholder="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ marginBottom: '20px' }}
        />

        <Button type="submit" fullWidth variant="solid" disabled={isLoading}>
          {isLoading ? (
            <>
              <CircularProgress size={24} sx={{ marginRight: "10px" }} /> {/* Spinner icon */}
              Loading...
            </>
          ) : (
            'Sign In'
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default Login;
