// context/SystemContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { getPlantDocument, getUserData, getAllDevicesInPlant, getLiveInverterMonitoringData, getLiveGridMonitoringData, getGridDayMonitoringData, getInverterDayMonitoringData, getInverterMonthlyData } from '../../library/firestoreService'; // Adjust the import path as needed
import { isSameDay } from '../../helpers/date';

const SystemContext = createContext();

/**
 * Class representing inverter monitoring data.
 */
class InverterMonitorData {
    /**
     * Create a new InverterMonitorData object.
     * @param {string} deviceId - The ID of the document.
     * @param {string} plantId - The ID of the plant.
     * @param {Array.<PowerDays>} powerDays - The array of PowerData per day objects.
     */
    constructor(documentId, plantId, powerDays) {
        this.documentId = documentId; // Assign the document ID
        this.plantId = plantId; // Assign the plant ID
        this.powerDays = powerDays; // Assign the power data array
    }
}

class GridMonitorData {
    constructor(plantId, powerDays) {
        this.plantId = plantId;
        this.powerDays = powerDays;
    }
}

/**
 * Class representing power data for specific days.
 */
class PowerDays {
    /**
     * Create a new PowerDays object.
     * @param {Date} date - The date for the power data.
     * @param {(Array.<ACPower>|Array.<InverterPower>)} powerArray - The array of power data. Can be an array of ACPower objects or an array of InverterPower objects.
     */
    constructor(date, powerArray) {
        this.date = date; // Assign the date
        this.powerArray = powerArray; // Assign the power data array
    }
}

class InverterPower {
    /**
     * Constructs an instance of the InverterPower class with detailed electrical parameters.
     * @param {Timestamp} timestamp - The data timestamp for the measurements.
     * @param {Object} iAc - Object containing AC current values for each phase (L1, L2, L3).
     * @param {Object} vAc - Object containing AC voltage values for each phase (L1, L2, L3).
     * @param {Object} iMPPT - Object containing current values for each Maximum Power Point Tracker (MPPT) with keys as their identifiers, excluding zeroes.
     * @param {Object} vMPPT - Object containing voltage values for each MPPT with keys as their identifiers, excluding zeroes.
     * @param {Object} vPv - Object containing voltage values for each photovoltaic (PV) string with keys as their identifiers, excluding zeroes.
     * @param {Object} iPv - Object containing current values for each PV string with keys as their identifiers, excluding zeroes.
     * @param {number} pac - The total active power value of the inverter.
     * @param {number} temperature - The average temperature value of the inverter.
     * @param {number} frequency - The frequency of the AC power in hertz.
     * @param {number} totalEnergyToday - The total AC current value of the inverter.
     * @param {number} totalEnergy - The total AC current value of the inverter.
     */
    constructor(timestamp, frequency, iAc, vAc, iMPPT, vMPPT, iPv, vPv, pac, temperature, totalEnergyToday, totalEnergy) {
        this.timestamp = timestamp;
        this.frequency = frequency;
        this.iAc = iAc;
        this.vAc = vAc;
        this.iMPPT = iMPPT;
        this.vMPPT = vMPPT;
        this.iPv = iPv;
        this.vPv = vPv;
        this.pac = pac;
        this.temperature = temperature;
        this.totalEnergyToday = totalEnergyToday;
        this.totalEnergy = totalEnergy;
    }
}

class ACPower {
    /**
     * Constructs an instance of the ACPower class with detailed electrical parameters.
     * @param {string} timestamp - The data timestamp for the measurements (in UTC format).
     * @param {number} frequency - The frequency of the AC power in hertz.
     * @param {Object} activePower - Object containing active power values (in watts) for each phase (L1, L2, L3).
     * @param {Object} reactivePower - Object containing reactive power values (in volt-amperes reactive) for each phase (L1, L2, L3).
     * @param {Object} apparentPower - Object containing apparent power values (in volt-amperes) for each phase (L1, L2, L3).
     * @param {Object} powerFactor - Object containing power factor values (dimensionless) for each phase (L1, L2, L3).
     * @param {Object} voltage - Object containing voltage values (in volts) for each phase (L1, L2, L3).
     * @param {Object} current - Object containing current values (in amperes) for each phase (L1, L2, L3).
     * @param {Object} ltolVoltage - Object containing line-to-line voltage values (in volts) between phase pairs (L1-L2, L2-L3, L3-L1).
     */
    constructor(timestamp, frequency, activePower, reactivePower, apparentPower, powerFactor, voltage, current, ltolVoltage) {
        this.timestamp = timestamp;
        this.frequency = frequency;
        this.activePower = activePower;
        this.reactivePower = reactivePower;
        this.apparentPower = apparentPower;
        this.powerFactor = powerFactor;
        this.voltage = voltage;
        this.ltolVoltage = {
            L1_L2: ltolVoltage?.L1_L2 ?? null,
            L2_L3: ltolVoltage?.L2_L3 ?? null,
            L3_L1: ltolVoltage?.L3_L1 ?? null
        };
        this.current = current;
    }

}

export const useSystemData = () => useContext(SystemContext);

export const SystemProvider = ({ children }) => {

    const [userId, setUserId] = useState(null);
    const [selectedSystemId, setSelectedSystemId] = useState(null);
    const [selectedSystemDate, setSelectedSystemDate] = useState({ date: new Date(), type: 'day' });
    const [devices, setDevices] = useState([]);
    const [userSystems, setUserSystems] = useState([]);
    const [inverterMonitoringData, setInverterMonitoringData] = useState([]);
    const [gridMonitoringData, setGridMonitoringData] = useState([]);


    // Effect to fetch user systems when userId changes
    useEffect(() => {
        if (!userId) return;
        const fetchUserSystems = async () => {
            try {
                const userData = await getUserData(userId);
                if (userData && userData.plants) {
                    const userSystemsPromises = userData.plants.map(async (plant) => {
                        const plantData = await getPlantDocument(plant.id);
                        return plantData;
                    });
                    const userSystems = await Promise.all(userSystemsPromises);
                    setUserSystems(userSystems);
                }
            } catch (error) {
                console.error('Error fetching user systems:', error);
            }
        };

        fetchUserSystems();
    }, [userId]);

    // Effect to fetch system day data when selectedSystemId changes
    useEffect(() => {

        let unsubscribeGridMonitoring;
        let unsubscribeInverterMonitoringArray = [];

        //Unsubscribe from the previous system's monitoring data
        const unsubcribeMonitoring = () => {
            console.log("Unsubscribing from monitoring data for plant: " + selectedSystemId);
            if (unsubscribeGridMonitoring) unsubscribeGridMonitoring();
            unsubscribeInverterMonitoringArray.forEach(unsubscribe => unsubscribe());
        }

        const fetchSystemDayData = async () => {
            // Check if the selected system exists in the user's systems
            const existingSystem = userSystems.find(system => system.id === selectedSystemId);
            if (!existingSystem) {
                console.log("No system found");
                return;
            }

            // Proceed to fetch data
            try {
                await fetchDataForSystem();
            } catch (error) {
                console.error(`Error fetching data for system ${selectedSystemId}:`, error);
            }
        };

        const fetchDataForSystem = async () => {

            // Fetch Data from Firebase
            const devices = await getAllDevicesInPlant(selectedSystemId);
            setDevices(devices);

            setupSubscriptions(devices, new Date());

        };

        const setupSubscriptions = (devices, today) => {
            unsubscribeGridMonitoring = getLiveGridMonitoringData(selectedSystemId, today, data => {

                if (data) {
                    //TODO Remove
                    //const gridPower = { date: today, power: data.power };
                    //updateUserSystems(null, gridPower);

                    updateGridMonitoringDataFromFirebase(selectedSystemId, data, today);
                } else {
                    console.log('No grid data found for this period.');
                }

            }, error => {
                console.error('Error fetching data:', error);
            });

            unsubscribeInverterMonitoringArray = devices.map(device =>

                getLiveInverterMonitoringData(device.documentId, today, data => {
                    if (data) {
                        updateInverterMonitoringDataFromFirebase(device, data, today);
                    } else {
                        console.log('No grid data found for this period.');
                    }
                }, error => {
                    console.error('Error fetching data:', error);
                }));
        };


        fetchSystemDayData();
        setSelectedSystemDate({ date: new Date(), type: 'day' });

        return () => {
            unsubcribeMonitoring();
        };
    }, [selectedSystemId]);

    useEffect(() => {

        const fetchSystemDayData = () => {

            //Check if system already has data in the stored data
            const selectedGridDay = gridMonitoringData?.find(x => x?.plantId === selectedSystemId)?.powerDays?.find(x => isSameDay(x?.date, selectedSystemDate?.date));
            // if data doesnt exist then read from database
            if (!selectedGridDay) {
                getGridDayMonitoringData(selectedSystemId, selectedSystemDate.date).then(docData => {
                    if (!docData) {
                        console.log('No grid data found for this period.');
                    } else {
                        updateGridMonitoringDataFromFirebase(selectedSystemId, docData, selectedSystemDate.date);
                    }
                });
            }

            devices.map(device => {
                const selectedInverterDay = inverterMonitoringData.find(x => x.documentId === device.documentId).powerDays.find(x => isSameDay(x.date, selectedSystemDate.date));
                //If selectedInverterDay is not found then read from database
                if (!selectedInverterDay) {
                    getInverterDayMonitoringData(device.documentId, selectedSystemDate.date).then(docData => {
                        if (!docData) {
                            console.log('No inverter data found for this period.');
                        } else {
                            updateInverterMonitoringDataFromFirebase(device, docData, selectedSystemDate.date);
                        }
                    });
                }

            });
        };

        const fetchSystemMonthData = () => {
            const selectedMonth = selectedSystemDate.date.getMonth();
            const selectedYear = selectedSystemDate.date.getFullYear();

            devices.forEach(device => {
                // Check if the current device has data for the selected month and year
                const hasDataForSelectedMonth = inverterMonitoringData.find(x=>x.documentId == device.documentId).powerMonth?.some(entry =>
                    entry.month === selectedMonth && entry.year === selectedYear);

                // Only fetch new data if there is no entry for the selected month and year
                if (!hasDataForSelectedMonth) {
                    getInverterMonthlyData(device.documentId, selectedSystemDate.date).then(docData => {
                        if (!docData) {
                            console.log('No inverter data found for this period.');
                        } else {
                            UpdateInverterMonitoringMonthlyData(device, docData, selectedSystemDate.date);
                        }
                    });
                }
            });
        };

        const fetchSystemYearData = () => { { } };

        switch (selectedSystemDate.type) {
            case 'day':
                //Check if system is today no need to fetch data
                if (isSameDay(selectedSystemDate.date, new Date())) return;
                fetchSystemDayData();
                break;
            case 'month':
                fetchSystemMonthData();
                break;
            case 'year':
                fetchSystemYearData();
                break;
            default:
                break;
        }


    }, [selectedSystemDate]);


    //FUNCTIONS-----------------------------------------------


    function updateGridMonitoringDataFromFirebase(selectedSystemId, data, date) {
        setGridMonitoringData(currentPlants => {
            let updatedPlants = [...currentPlants]; // Create a new array to ensure React detects a change

            const index = updatedPlants.findIndex(x => x.plantId === selectedSystemId);
            const newGridPowerArray = data.power.map(power => new ACPower(power.timestamp, power.frequency, power.activePower, power.reactivePower, power.apparentPower, power.powerFactor, power.voltage, power.current, power.ltolVoltage));

            if (index === -1) {
                // Plant doesn't exist, add a new one
                const newPlant = new GridMonitorData(selectedSystemId, [new PowerDays(date, newGridPowerArray)], null);
                updatedPlants = updatedPlants.concat(newPlant);
            } else {
                // Plant exists, update its gridPowerArray for today
                const selectedGridDay = updatedPlants[index].powerDays.find(x => isSameDay(x.date, date));
                if (selectedGridDay) {
                    selectedGridDay.powerArray = newGridPowerArray;
                } else {
                    //power day not found should push new data
                    updatedPlants[index].powerDays.push(new PowerDays(date, newGridPowerArray));
                }
            }
            return updatedPlants; // Return the new array to update the state
        });
    }

    function updateInverterMonitoringDataFromFirebase(device, data, date) {
        setInverterMonitoringData(currentDevices => {
            let updatedDevices = [...currentDevices]; // Create a new array to ensure React detects a change

            const index = updatedDevices.findIndex(x => x.documentId === device.documentId);
            if (index === -1) {
                // Device doesn't exist, add a new one
                const newInverterPowerArray = data.power.map(power => new InverterPower(power.timestamp, power.frequency, power.iAc, power.vAc, power.iMPPT, power.vMPPT, power.iPv, power.vPv, power.pac, power.temperature, power.todayTotalEnergy, power.totalEnergy));
                const newDevice = new InverterMonitorData(device.documentId, device.plantId, [new PowerDays(date, newInverterPowerArray)]);
                updatedDevices = updatedDevices.concat(newDevice); // Use concat or spread to add new device
            } else {
                // Device exists, update its powerArray for today
                const updatedDevice = { ...updatedDevices[index] }; // Create a new object for the device
                const selectedPowerDay = updatedDevice.powerDays.find(x => isSameDay(x.date, date));
                if (selectedPowerDay) {
                    selectedPowerDay.powerArray = data.power.map(power => new InverterPower(power.timestamp, power.frequency, power.iAc, power.vAc, power.iMPPT, power.vMPPT, power.iPv, power.vPv, power.pac, power.temperature, power.todayTotalEnergy, power.totalEnergy));
                } else {
                    //power day not found should push new data
                    updatedDevice.powerDays.push(new PowerDays(date, data.power.map(power => new InverterPower(power.timestamp, power.frequency, power.iAc, power.vAc, power.iMPPT, power.vMPPT, power.iPv, power.vPv, power.pac, power.temperature, power.todayTotalEnergy, power.totalEnergy))));
                }
                updatedDevices[index] = updatedDevice; // Replace the device in the array with the updated one
            }

            return updatedDevices; // Return the new array to update the state
        });
    }

    function UpdateInverterMonitoringMonthlyData(device, data, date) {
        setInverterMonitoringData(currentDevices => {
            let updatedDevices = [...currentDevices]; // Create a new array to ensure React detects a change

            const selectedDevice = updatedDevices.find(x => x.documentId === device.documentId);
            const powerMonthExists = selectedDevice.powerMonth;
            if (!powerMonthExists) {
                selectedDevice.powerMonth = [{ month: date.getMonth(), year: date.getFullYear(), powerArray: data.power }];
            } else {
                selectedDevice.powerMonth.push({ month: date.getMonth(), year: date.getFullYear(), powerArray: data.power });
            }


            return updatedDevices; // Return the new array to update the state
        });
    }


    return (
        <SystemContext.Provider value={{
            userSystems,
            setUserId,
            selectedSystemId,
            setSelectedSystemId,
            inverterMonitoringData,
            gridMonitoringData,
            setSelectedSystemDate,
            selectedSystemDate,
        }}>
            {children}
        </SystemContext.Provider>
    );
};
