

/**
 * This function checks if two dates fall on the same day.
 *
 * @param {Date} date1 - The first date to be compared.
 * @param {Date} date2 - The second date to be compared.
 *
 * @returns {boolean} - Returns true if the dates fall on the same day, false otherwise.
 *
 * @example
 * // Check if two dates fall on the same day
 * const sameDay = isSameDay(new Date('2022-01-01'), new Date('2022-01-01'));
 * console.log(sameDay); // Logs: true
 */
export const isSameDay = (date1, date2) => {

    const d1 = new Date(date1);
    const d2 = new Date(date2);
    
    return d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate();
};

/**
 * This function adds or subtracts a number of days from a date.
 *
 * @param {Date} date - The date to be modified.
 * @param {number} days - The number of days to add or subtract. Use a positive number to add days and a negative number to subtract days.
 *
 * @returns {Date} - Returns a new date with the specified number of days added or subtracted.
 *
 * @example
 * // Add 3 days to a date
 * const newDate = addOrSubtractDays(new Date('2022-01-01'), 3);
 * console.log(newDate); // Logs: 2022-01-04
 *
 * // Subtract 3 days from a date
 * const newDate = addOrSubtractDays(new Date('2022-01-01'), -3);
 * console.log(newDate); // Logs: 2021-12-29
 */
export const adjustDateByDays = (date, days) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
};

export const adjustDateByMonths = (date, months) => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + months);
    return newDate;
}