import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signOut } from "firebase/auth";
import { firebaseApp } from '../../../library/firebase';
import { getAuth } from "firebase/auth";
import AlertDialogModal from '../../../doms/components/modal';
import styles from './NavBar.module.css';
import logo512 from '../../../images/logo512.png';
import { Dashboard, Settings, ExitToApp } from '@mui/icons-material';

import { UserContext } from '../../context/UserContext';

const NavBar = ({ isNavOpen, toggleNav }) => {

  const user = useContext(UserContext);
  const auth = getAuth(firebaseApp);

  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
      toggleNav(); // Close the nav after logout
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  // Close nav when a link is clicked
  const handleLinkClick = () => {
    if (window.innerWidth < 700) {
      toggleNav();
    }
  };

  return (
    <div className={isNavOpen ? `${styles.nav} ${styles.open}` : styles.nav}>
      <div className={styles.navHeader}>
        <button className={styles.navCloseBtn} onClick={toggleNav}>
          X
        </button>
        <img src={logo512} alt="Blueshift Logo" className={styles.navLogo} />
      </div>

      <div className={styles.navLinks}>
        <Link to="/" className={styles.navLink} onClick={handleLinkClick}>
          <Dashboard fontSize="small" style={{ marginRight: '8px' }} /> Dashboard
        </Link>
        {user?.uid === 'OQaQYm97KHWFLA0VCx1ZwMrfkM32' && (
          <Link to="/settings" className={styles.navLink} onClick={handleLinkClick}>
            <Settings fontSize="small" style={{ marginRight: '8px' }} /> Settings
          </Link>
        )}
        <Link onClick={() => { setOpenDialog(true); handleLinkClick(); }} className={styles.navLink}>
          <ExitToApp fontSize="small" style={{ marginRight: '8px' }} /> Logout
        </Link>
        {/* Add other links as needed */}
      </div>


      {/* Dialog for Logout Confirmation */}
      <AlertDialogModal heading={"Logout"} description={"Are you sure you want to logout?"} buttonText={"Logout"} open={openDialog} setOpen={setOpenDialog} onConfirmation={handleLogout} />

    </div>
  );
};

export default NavBar;
