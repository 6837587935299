import React, { useEffect, useState, useContext } from 'react';
import { SystemParentCard, ComparisonCard, OpticasterCard, PowerHubCard, WarrantyCard, RequestServicesCard } from '../../components/DataCards/DataCards';
import styles from './dashboard.module.css'
import Grid from '@mui/material/Grid';
import { getPlantDocument, getUserData } from '../../../library/firestoreService';

import { UserContext } from '../../context/UserContext';
import { useSystemData } from '../../context/SystemContext';

import Snackbar from '@mui/joy/Snackbar';

const Dashboard = () => {

  const user = useContext(UserContext);
  const { userSystems, setUserId, setSelectedSystemId, selectedSystemId, selectedSystem} = useSystemData();

  //Use States--------------------------------------------

  //State for the last time the data was updated
  const [updatedTime, setUpdateTime] = useState(null);

  // State for loading status of each card
  const [systemParentCardLoading, setSystemParentCardLoading] = useState(false);
  const [opticasterCardLoading, setOpticasterCardLoading] = useState(true);
  const [comparisonCardLoading, setComparisonCardLoading] = useState(true);
  const [powerHubCardLoading, setPowerHubCardLoading] = useState(true);
  const [warrantyCardLoading, setWarrantyCardLoading] = useState(true);
  const [requestServicesCardLoading, setRequestServicesCardLoading] = useState(true);

  // State for error handling
  const [errorMessage, setErrorMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Use Effect for user change
  useEffect(() => {
    if (user && user.uid) {
      setUserId(user.uid);
    } else{
      console.log("User is not logged in");
    }

  }, [user]);


  //Functions--------------------------------------------


  /*const updateLoadingStatus = () => {
    // check conditions for systemparentcard
    if (plantDayData && plantDayData.devices && plantDayData.devices[0] && plantDayData.devices[0].plantId === selectedSystemId) {
      console.log("Done Loading");
      setSystemParentCardLoading(false);
    } else {
      console.log("Loading");
      setSystemParentCardLoading(true);
    }
  };*/


  const dataSet = [
    { name: 'You', value: 0.21 },
    { name: '1', value: 0.18 },
    { name: '2', value: 0.21 },
    { name: '3', value: 0.21 },
    { name: '4', value: 0.15 },
  ];


  return (
    //Overviews
    <div className={styles.page}>
      <Grid container
        direction="row"

        spacing={2}>
        <Grid item sx={{ display: "flex", flexDirection: "column" }} lg={8} md={12} sm={12} xs={12}>
          <SystemParentCard isLoading={systemParentCardLoading} updatedTime={updatedTime} ></SystemParentCard>
        </Grid>
        <Grid item sx={{ display: "flex", flexDirection: "column" }} lg={4} md={6} sm={6} xs={12}>
          <OpticasterCard isLoading={opticasterCardLoading} data={dataSet}></OpticasterCard>
        </Grid>
        <Grid item sx={{ display: "flex", flexDirection: "column" }} lg={8} md={12} sm={12} xs={12}>
          <PowerHubCard isLoading={powerHubCardLoading}></PowerHubCard>
        </Grid>
        <Grid item sx={{ display: "flex", flexDirection: "column" }}  lg={4} md={6} sm={6} xs={12}>
          <WarrantyCard isLoading={warrantyCardLoading}></WarrantyCard>
        </Grid>
      </Grid>

      {/* Snackbar for displaying errors */}
      <Snackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        color='danger'
        variant='outlined'
        autoHideDuration={3000} // Adjust duration as needed
      >
        {errorMessage}
      </Snackbar>

    </div >


  );
};

export default Dashboard;