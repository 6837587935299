import React, { useState, useEffect } from 'react';
import styles from './DataCards.module.css'
import { Grid, Card, CardContent, Typography, Box, CardActionArea, Button, LinearProgress } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, LabelList, Tooltip, Legend, ResponsiveContainer, AreaChart, Area, Cell, CartesianGrid } from 'recharts';
import { Select, Option, Radio, RadioGroup } from '@mui/joy';
import { convertPower } from '../../../helpers/units';
import { isSameDay, adjustDateByDays, adjustDateByMonths } from '../../../helpers/date';

import SolarPowerIcon from '@mui/icons-material/SolarPower';
import BatteryStdIcon from '@mui/icons-material/BatteryStd';
import GiteIcon from '@mui/icons-material/Gite';
import FactoryIcon from '@mui/icons-material/Factory';
import DownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import LeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import RightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import UpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import PhotoIcon from '@mui/icons-material/InsertPhoto';
import OneLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import OneRightIcon from '@mui/icons-material/KeyboardArrowRight';

import ImageViewer from 'react-simple-image-viewer';

import Skeleton from '@mui/material/Skeleton';

import DefaultSolarImage from '../../../images/site.jpg';
import { useSystemData } from '../../context/SystemContext';
import { getPlantImageURL } from '../../../library/firestoreService';


export const SystemParentCard = ({ isLoading, updatedTime }) => {

  const { userSystems, setSelectedSystemId, selectedSystemId, inverterMonitoringData } = useSystemData();

  //Use States-----------------------------------------------
  //for handling time updated
  const [selectedSystem, setSelectedSystem] = useState(null);
  const [todayPower, setTodayPower] = useState(0);
  const [totalPower, setTotalPower] = useState(0);

  const [openImageDialog, setOpenImageDialog] = React.useState(false);

  const [siteImages, setSiteImages] = React.useState([DefaultSolarImage]);

  //Use Effects-----------------------------------------------

  useEffect(() => {

    const fetchImages = async () => {
      if (selectedSystemId) {
        const images = await getPlantImageURL(selectedSystemId);
        if(images.length > 0){
          setSiteImages(images);
        }
      }
    };

    setSelectedSystem(userSystems.find(system => system.id === selectedSystemId));
    setSiteImages([DefaultSolarImage]);
    fetchImages();


  }, [selectedSystemId]);

  useEffect(() => {
    if (userSystems && userSystems.length > 0 && selectedSystemId === null) {
      setSelectedSystemId(userSystems[0].id);
    }
  }, [userSystems]);

  useEffect(() => {
    {
      const plantDevices = inverterMonitoringData.filter(x => x.plantId === selectedSystemId);
      const latestPowerArray = plantDevices.map(x => x.powerDays.find(x => isSameDay(x.date, new Date()))?.powerArray.sort((a, b) => b.timestamp.toDate() - a.timestamp.toDate())[0])
      setTodayPower(
        latestPowerArray
          .map(x => x.totalEnergyToday)
          .reduce((acc, curr) => (typeof curr === 'number' ? acc + curr : acc), 0)
      );
      setTotalPower(latestPowerArray.map(x => x.totalEnergy).reduce((acc, curr) => (typeof curr === 'number' ? acc + curr : acc), 0));
    }
  }, [inverterMonitoringData])


  //Handles--------------------------------------

  const changeSystemSelection = (event, newValue) => {
    setSelectedSystemId(newValue);
  };

  const imagePreviewClick = () => {
    console.log("Image Clicked");
    setOpenImageDialog(true);
  }

  const imagePreviewClose = () => {
    setOpenImageDialog(false);
  }

  if (isLoading) {
    return (<Skeleton variant="rounded" width="100%" height="100%" />);
  }

  return (

    <Box p={3} className="border rounded bg-white shadow h-100">
      <Box display="flex" justifyContent="space-between" flexWrap="wrap" alignItems="center">
        <Box mb={2}>

          <Typography variant='h5' sx={{ color: 'var(--primary)' }}>
            System info
          </Typography>


        </Box>

        <Select
          value={selectedSystemId}
          variant='outlined'
          sx={{ marginRight: 1 }}
          onChange={changeSystemSelection}

        >
          {userSystems && userSystems.map(option => {
            return option != null ?
              <Option key={option.id} value={option.id}> {option?.name ?? "No Name"} </Option>
              : null;
          })}
        </Select>

      </Box>

      <Grid container marginTop={2} spacing={1}>

        <Grid item md={7} xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <MultipleDataCard dataSet={
                [
                  { label: 'Status', value: 'Active' },
                  { label: 'Capacity', value: convertPower(selectedSystem?.installation?.map(item => item.solar.wattage * item.solar.pcs).reduce((acc, curr) => acc + curr, 0), 'w', "kw", 2) + " kW" },
                  { label: 'Solar Today', value: todayPower + " kWh" },
                ]
              } />
            </Grid>
            <Grid item xs={6}>
              <SingleDataCard label={"Total Power"} value={convertPower(totalPower, "kw", "mw", 2)} unit={"mWh"} />
            </Grid>
            <Grid item xs={6}>
              <SingleDataCard label={"Estimated Earnings"} value={"₱ " + (totalPower * (selectedSystem?.distribution_utility?.temp === 'Business' ? 8 : 12)).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} unit={""} />
            </Grid>
            <Grid item xs={12}>
              <SingleDataCard
                label={"Sun Hours"}
                value={todayPower / convertPower(selectedSystem?.installation?.map(item => item.solar.wattage * item.solar.pcs).reduce((acc, curr) => acc + curr, 0), 'w', "kw", 2)}
                unit={"h"}
                type='progress'
                progressMax={5}
                description={todayPower / convertPower(selectedSystem?.solar?.map(item => item.wattage * item.pcs).reduce((acc, curr) => acc + curr, 0), 'w', "kw", 2) > 3.2 ? 'You are doing better than the daily average.' : 'Currently performing lower than daily average. May improve as the day goes on.'}
              />
            </Grid>
          </Grid>

        </Grid>



        <Grid item md={5} xs={12} sx={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
          <Box className="bg-blur rounded color-w" sx={{ display: "flex", alignItems: "center", position: 'absolute', bottom: 15, left: 15, padding: "5px 10px" }}>
            <PhotoIcon sx={{ marginRight: "5px" }} />
            <span className='text-1'>{siteImages.length}</span>
          </Box>
          <img src={siteImages[0]} alt="Your Solar System img" className='w-100 h-100 rounded img clickable' onClick={imagePreviewClick} />

        </Grid>
      </Grid>

      {openImageDialog && (
        <div style={{ zIndex: 2, position: 'relative' }}>
          <ImageViewer
            src={siteImages}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={imagePreviewClose}
          />
        </div>
      )}
    </Box>
  );
}

export const ComparisonCard = ({ data }) => {

  const renderCustomBarLabel = (props) => {
    const { x, y, width, height, value } = props;
    // Calculate the position based on the x, y, width, and height
    // Return custom SVG element, text, or set of elements
    return <text x={x + width / 2} y={y + 20} fill="#FFF" textAnchor="middle" dominantBaseline="central">{(value * 100).toFixed(0)}%</text>;
  };

  return (
    <Box p={3} className="border rounded bg-white shadow h-100 d-flex flex-column">
      <Typography variant='h5' sx={{ color: 'var(--primary)' }}>Performance</Typography>
      <Typography variant='body' mb={4} className='color-light'>Compare your capacity factor with people near you. Know your performance.</Typography>

      {/* Spacer Element */}
      <div style={{ flexGrow: 1 }}></div>
      <ResponsiveContainer width="100%" minHeight="240px">
        <BarChart data={data}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#83a6ed" stopOpacity={1} />
              <stop offset="99%" stopColor="#83a6ed" stopOpacity={0.6} />
            </linearGradient>
            <linearGradient id="gradientBlack" x1="0" y1="0" x2="0" y2="1">
              <stop offset="20%" stopColor="#000" stopOpacity={1} />
              <stop offset="100%" stopColor="#000" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" tickLine={false} axisLine={false} />
          <Tooltip formatter={(data) => `${(data * 100).toFixed(0)}%`} />
          <Bar dataKey="value" fill="url(#colorUv)">
            {
              data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={index === 0 ? 'url(#gradientBlack)' : 'url(#colorUv)'} />
              ))
            }
            <LabelList content={renderCustomBarLabel} />
          </Bar>

        </BarChart>
      </ResponsiveContainer>
    </Box>

  );
}

export const PowerHubCard = () => {

  const { selectedSystemId, inverterMonitoringData, gridMonitoringData, setSelectedSystemDate, selectedSystemDate } = useSystemData();

  const timeRadioOptions = [
    { label: 'Day', value: "Day" },
    { label: 'Month', value: "Month" },
  ];

  //states--------------------------------------
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [combinedSystemData, setCombinedSystemData] = useState(false);

  const [viewTime, setViewTime] = useState("Day");
  const [dateRange, setDateRange] = useState("Real-Time");


  // Use effects--------------------------------------

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    if (!inverterMonitoringData && !gridMonitoringData) {
      console.log("Cancelled");
      return;
    }

    showSelectedGraph();

  }, [inverterMonitoringData, gridMonitoringData]);


  useEffect(() => {

    switch (viewTime) {
      case 'Day':
        setSelectedSystemDate({ date: new Date(), type: "day" })
        break;
      case 'Month':
        setSelectedSystemDate({ date: new Date(), type: "month" })
        break;
      case 'Year':
        setSelectedSystemDate({ date: new Date(), type: "year" })

        break;
      default:
    }
  }, [viewTime]);

  useEffect(() => {
    setViewTime("Day");
  }, [selectedSystemId]);


  useEffect(() => {
    showSelectedGraph();

    switch (selectedSystemDate.type) {
      case "day":
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set the time to 00:00:00 to compare only the date part

        const selectedDate = new Date(selectedSystemDate.date);
        selectedDate.setHours(0, 0, 0, 0); // Set the time to 00:00:00 to compare only the date part

        //If Date is selected today
        if (+selectedDate === +today) {
          setDateRange("Real-Time");
        } else {
          setDateRange(selectedDate.toDateString());
        }
        break;
      case "month":
        setDateRange(selectedSystemDate.date.toLocaleString('default', { month: 'long', year: 'numeric' }));
        break;
      case "year":
        setDateRange(selectedSystemDate.date.getFullYear());
        break;
      default:
        break;
    }


  }, [selectedSystemDate]);

  //Handlers----------------------------------------

  function backClicked() {
    switch (selectedSystemDate.type) {
      case "day":
        setSelectedSystemDate({ date: adjustDateByDays(selectedSystemDate.date, -1), type: "day" });
        break;
      case "month":
        setSelectedSystemDate({ date: adjustDateByMonths(selectedSystemDate.date, -1), type: "month" });
        break;
      case "year":
        setSelectedSystemDate({ date: adjustDateByMonths(selectedSystemDate.date, -12), type: "year" });
        break;
      default:
        break;
    }

  }

  function forwardClicked() {
    const newDate = adjustDateByDays(selectedSystemDate.date, +1);
    newDate.setHours(0, 0, 0, 0); // Set the time to 00:00:00 to compare only the date part
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set the time to 00:00:00 to compare only the date part

    if (newDate > today) {
      return;
    }

    switch (selectedSystemDate.type) {
      case "day":
        setSelectedSystemDate({ date: newDate, type: "day" });
        break;
      case "month":
        setSelectedSystemDate({ date: adjustDateByMonths(selectedSystemDate.date, +1), type: "month" });
        break;
      case "year":
        setSelectedSystemDate({ date: adjustDateByMonths(selectedSystemDate.date, +12), type: "year" })
        break;
      default:
        break;
    }


  }


  //Functions--------------------------------------

  function showSelectedGraph() {

    const devicesInSelectedPlant = inverterMonitoringData.filter(x => x.plantId === selectedSystemId);

    switch (selectedSystemDate.type) {
      case "day":
        const devicesPowerSelectedDay = devicesInSelectedPlant.map(x => x.powerDays.find(x => isSameDay(x.date, selectedSystemDate.date)));
        const gridDataSelected = gridMonitoringData?.find(x => x.plantId === selectedSystemId)?.powerDays?.find(x => isSameDay(x.date, selectedSystemDate.date));

        setCombinedSystemData(processDayData(gridDataSelected, devicesPowerSelectedDay));
        break;
      case "month":
        console.log(devicesInSelectedPlant);
        const devicesPowerSelectedMonth = devicesInSelectedPlant.map(x =>
          x.powerMonth
            ? x.powerMonth.find(x => x.month === selectedSystemDate.date.getMonth() && x.year === selectedSystemDate.date.getFullYear())
            : undefined
        );
        const gridDataSelectedMonth = gridMonitoringData?.find(x => x.plantId === selectedSystemId)?.powerMonth?.find(x => x.month === selectedSystemDate.date.getMonth() + 1 && x.year === selectedSystemDate.date.getFullYear());

        setCombinedSystemData(processMonthData(gridDataSelectedMonth, devicesPowerSelectedMonth));
        break;
      case "year":
        break;
      default:
        break;
    }
  }

  function shouldRenderArea(data, dataKey) {
    // Check if data is empty
    if (!data || data.length === 0) {
      return false;
    }

    // Check if all values for the dataKey are zero
    return data.some(item => item[dataKey] !== 0);
  }

  function processDayData(gridData, devicesPowerToday) {
    if (gridData == null && devicesPowerToday == null) {
      console.log("Grid and Solar Data missing...");
      return;
    }

    let entries = {};

    // Rounded to nearest 5 mins
    function roundDate(date) {
      const minutes = date.getMinutes();
      const roundedMinutes = Math.round(minutes / 5) * 5;
      date.setMinutes(roundedMinutes);
      date.setSeconds(0, 0);
      return date;
    }

    // Process solar production from all devices
    devicesPowerToday?.forEach((devicesPower) => {
      if (!devicesPower) {
        return;
      }
      devicesPower.powerArray.forEach((power) => {
        const date = roundDate(power.timestamp.toDate()); // Convert Firebase Timestamp to JavaScript Date and round it
        const key = date.toISOString(); // Use ISO string for unique key

        // Initialize if not already present
        if (!entries[key]) entries[key] = { solar: 0, grid: 0, consumption: 0, time: date };

        entries[key].solar += Math.round(power.pac);
      });
    });

    // Process grid data
    gridData?.powerArray.forEach((data) => {
      const date = roundDate(data.timestamp.toDate()); // Convert Firebase Timestamp to JavaScript Date and round it
      const key = date.toISOString(); // Use ISO string for unique key

      // Initialize if not already present
      if (!entries[key]) entries[key] = { solar: 0, grid: 0, consumption: 0, time: date };

      entries[key].grid = Math.round(data.activePower.L1 + data.activePower.L2 + data.activePower.L3);
    });

    // Calculate consumption for each entry
    Object.keys(entries).forEach((key) => {
      const entry = entries[key];
      // If grid is not 0, calculate consumption as solar + absolute value of grid. If grid is 0, set consumption to 0.
      entry.consumption = entry.grid !== 0 ? Math.round(entry.solar + Math.abs(entry.grid)) : 0;
    });

    // Convert entries object to array
    return Object.values(entries).map(entry => {
      return {
        time: entry.time,
        solar: entry.solar,
        grid: entry.grid,
        battery: 0, // Static as per instructions
        consumption: entry.consumption
      };
    }).sort((a, b) => a.time - b.time);
  }

  function processMonthData(gridData, deviceData) {
    let entries = {};

    // Initialize entries for every day of the month
    const date = new Date(selectedSystemDate.date.getFullYear(), selectedSystemDate.date.getMonth() + 1, 0);
    const daysInMonth = date.getDate(); // Get the number of days in the selected month

    for (let day = 1; day <= daysInMonth; day++) {
      entries[day] = { solar: 0, grid: 0, consumption: 0, day: day };
    }

    // Process solar production from all devices
    deviceData?.forEach((device) => {
      if (!device) {
        return;
      }
      device.powerArray.forEach((power) => {
        // Update the existing entry
        if (entries[power.day]) {
          entries[power.day].solar += Math.round(power.energyToday);
        }
      });
    });

    // Process grid data
    gridData?.powerArray.forEach((data) => {
      // Update the existing entry
      if (entries[data.day]) {
        entries[data.day].grid = Math.round(data.activePower.L1 + data.activePower.L2 + data.activePower.L3);
      }
    });

    // Calculate consumption for each entry
    Object.keys(entries).forEach((day) => {
      const entry = entries[day];
      // If grid is not 0, calculate consumption as solar + absolute value of grid. If grid is 0, set consumption to 0.
      entry.consumption = entry.grid !== 0 ? Math.round(entry.solar + Math.abs(entry.grid)) : 0;
    });

    //Get Todays data to show also updated on the chart
    if (selectedSystemDate.date.getMonth() === new Date().getMonth() && selectedSystemDate.date.getFullYear() === new Date().getFullYear()) {
      const today = new Date().getDate(); // Get the current day
      entries[today].solar = inverterMonitoringData.filter(x => x.plantId === selectedSystemId).map(x => x.powerDays.find(x => isSameDay(x.date, new Date()))?.powerArray.sort((a, b) => b.timestamp.toDate() - a.timestamp.toDate())[0]).map(x => x.totalEnergyToday).reduce((acc, curr) => (typeof curr === 'number' ? acc + curr : acc), 0);
    }

    // Convert entries object to array
    return Object.values(entries).map(entry => {
      return {
        day: entry.day,
        solar: entry.solar,
        grid: entry.grid,
        consumption: entry.consumption
      };
    }).sort((a, b) => a.day - b.day);
  }

  function processYearData(gridData, deviceData) { }


  return (
    <Box sx={{ overflow: "hidden" }} className="border rounded shadow h-100 d-flex flex-column bg-white">

      <Grid container spacing={3} p={3}>

        {/** Title & Text */}
        <Grid item xs={12} md={5}>

          <Typography variant='h5' sx={{ color: 'var(--primary)' }}>Powerhub</Typography>
        </Grid>

        {/**controls */}
        <Grid item xs={12} md={7}>

          <Typography
            variant='body'
            sx={{
              color: "gray",
              textAlign: "center",
              display: "block",
              marginBottom: "10px",
              position: 'relative'
            }}
            className={dateRange === 'Real-Time' ? styles.blinkSlow : ''}
          >
            {dateRange}
          </Typography>

          <Box className="d-flex" sx={{ width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

            <Box sx={{ display: 'flex', alignItems: 'center' }}> {/* New flex container */}

              <Button sx={{ color: 'black' }} onClick={backClicked}>
                <OneLeftIcon></OneLeftIcon>
              </Button>

              <RadioGroup
                orientation="horizontal"
                aria-labelledby="segmented-controls-example"
                value={viewTime}
                onChange={(event) => setViewTime(event.target.value)}
                sx={{
                  minHeight: 32,
                  padding: '4px',
                  borderRadius: '8px',
                  bgcolor: 'neutral.softBg',
                  '--RadioGroup-gap': '4px',
                  '--Radio-actionRadius': '8px',
                }}
              >
                {timeRadioOptions.map((item) => (
                  <Radio
                    checked={viewTime === item.value}
                    key={item.value}
                    color="neutral"
                    value={item.value}
                    disableIcon
                    label={item.label}
                    variant="plain"
                    sx={{
                      px: 2,
                      alignItems: 'center',
                    }}
                    slotProps={{
                      action: ({ checked }) => ({
                        sx: {
                          ...(checked && {
                            bgcolor: 'background.surface',
                            boxShadow: 'sm',
                            '&:hover': {
                              bgcolor: 'background.surface',
                            },
                          }),
                        },
                      }),
                    }}
                  />
                ))}
              </RadioGroup>

              <Button sx={{ color: 'black' }} onClick={forwardClicked}>
                <OneRightIcon></OneRightIcon>
              </Button>

            </Box>

          </Box>

        </Grid>


      </Grid>


      <ResponsiveContainer
        width={windowWidth > 700 ? "95%" : "100%"}
        height="100%"
        minHeight="350px"
        style={{ margin: windowWidth > 700 ? "20px auto 0" : "0" }}
      >

        {selectedSystemDate.type === 'month' ? (
          // Render bar graph for monthly view
          <BarChart
            data={combinedSystemData}
          >
            <XAxis
              dataKey="day"
              interval={1}
              style={{
                display: windowWidth < 700 ? 'none' : 'block',
              }}
            />
            <YAxis
              style={{
                display: windowWidth < 700 ? 'none' : 'block',
              }}
            />
            <Tooltip
              labelFormatter={(label) => {
                if (!label) { return }
                // Create a date object from the label string
                return selectedSystemDate.date.toLocaleString('default', { month: 'long' }) + ' ' + label;
              }}
              formatter={(value, name) => {
                let formattedValue = `${value} kW`;
                return [formattedValue, name];
              }}
            />

            <Legend verticalAlign="top" height={36} />

            {shouldRenderArea(combinedSystemData, 'solar') && (
              <Bar dataKey="solar" stackId="a" stroke="rgb(205, 205, 0)" fill="rgba(205, 205, 0, 0.6)" strokeWidth={1} />
            )}
            {shouldRenderArea(combinedSystemData, 'grid') && (
              <Bar dataKey="grid" stackId="a" fill="rgb(113, 113, 113)" />
            )}
            {shouldRenderArea(combinedSystemData, 'consumption') && (
              <Bar dataKey="consumption" stackId="b" fill="rgb(0, 172, 14)" />
            )}
          </BarChart>

        ) : (
          <AreaChart data={combinedSystemData}>

            <Tooltip
              labelFormatter={(label) => {
                if (!label) { return }
                // Create a date object from the label string
                const date = new Date(label);
                // Format the time in AM/PM format
                let hours = date.getHours();
                let minutes = date.getMinutes();
                const ampm = hours >= 12 ? 'pm' : 'am';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                minutes = minutes < 10 ? '0' + minutes : minutes;
                const strTime = `${hours}:${minutes} ${ampm}`;
                return strTime;
              }}
              formatter={(value, name) => {
                let formattedValue = `${(value / 1000).toFixed(2)} kW`;
                return [formattedValue, name];
              }}
            />
            {windowWidth > 700 && (
              <>
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <Legend verticalAlign="top" height={36} wrapperStyle={{ color: 'black' }} />
                <YAxis tickLine={false} axisLine={false} />
              </>
            )}

            <XAxis
              dataKey="time"
              tickLine={false}
              axisLine={false}
              interval={windowWidth < 700 ? 0 : 24}
              tickFormatter={(time) => {
                if (!time) { return };
                return `${time.getHours()}:${time.getMinutes().toString().padStart(2, '0')}`;
              }}
              style={{
                display: windowWidth < 700 ? 'none' : 'block',
              }}
            />

            <defs>
              <linearGradient id="colorSolar" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="rgba(205, 205, 0, 1)" stopOpacity={0.8} />
                <stop offset="95%" stopColor="rgba(205, 205, 0, 1)" stopOpacity={0.2} />
              </linearGradient>
              <linearGradient id="colorGrid" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="rgba(113, 113, 113, 1)" stopOpacity={0.8} />
                <stop offset="95%" stopColor="rgba(113, 113, 113, 1)" stopOpacity={0.2} />
              </linearGradient>
              <linearGradient id="colorConsumption" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="rgba(0, 172, 14, 1)" stopOpacity={0.8} />
                <stop offset="95%" stopColor="rgba(0, 172, 14, 1)" stopOpacity={0.2} />
              </linearGradient>
            </defs>


            {shouldRenderArea(combinedSystemData, 'solar') && (
              <Area type="natural" dataKey="solar" stroke="rgb(205, 205, 0)" fill="url(#colorSolar)" strokeWidth={2} />
            )}

            {shouldRenderArea(combinedSystemData, 'battery') && (
              <Area type="natural" dataKey="battery" stroke="rgb(0, 78, 246)" fill="rgba(0, 78, 246, 1)" strokeWidth={2} />
            )}
            {shouldRenderArea(combinedSystemData, 'grid') && (
              <Area type="natural" dataKey="grid" stroke="rgb(113, 113, 113)" fill="url(#colorGrid)" strokeWidth={2} />
            )}
            {shouldRenderArea(combinedSystemData, 'consumption') && (
              <Area type="natural" dataKey="consumption" stroke="rgb(0, 172, 14)" fill="url(#colorConsumption)" strokeWidth={2} />
            )}


          </AreaChart>
        )}
      </ResponsiveContainer>


    </Box >
  );
}

export const OpticasterCard = () => {

  const { selectedSystemId, inverterMonitoringData, gridMonitoringData } = useSystemData();

  const [solarData, setSolarData] = useState(0);
  const [batteryData, setBatteryData] = useState(0);
  const [gridData, setGridData] = useState(0);
  const [consumptionData, setConsumptionData] = useState(0);


  // Effect to process plantDayData when it changes
  useEffect(() => {
    if (selectedSystemId != null) {
      const processedData = processData(inverterMonitoringData, gridMonitoringData);

      setSolarData(processedData.solarData);
      setBatteryData(processedData.batteryData);
      setGridData(processedData.gridData);
      setConsumptionData(processedData.consumptionData);
    }
  }, [inverterMonitoringData, gridMonitoringData]); // Depend directly on the state tracking the current selected system's plantDayData


  function processData(inverterData, gridData) {
    if (!inverterData && gridData) {
      return;
    }

    let entry = { solar: 0, grid: 0, consumption: 0 };
    const today = new Date();

    // Process solar production from the last device data
    const currentDevices = inverterData.filter(x => x.plantId == selectedSystemId)
    const currentDayDevicePowers = currentDevices.map(x => x.powerDays.find(x => isSameDay(x.date, today)));
    const latestDevicePower = currentDayDevicePowers.map(x => x.powerArray[0]);
    const combinedLastPowerPac = latestDevicePower.map(x => x.pac).reduce((acc, curr) => acc + curr, 0);
    entry.solar = Math.round(combinedLastPowerPac);

    // Process grid data from the last grid data
    if (gridData && gridData.length > 0) {
      const selectedPlantGridPower = gridData.find(x => x.plantId == selectedSystemId);
      const currentDateGridPower = selectedPlantGridPower?.powerDays.find(x => isSameDay(x.date, today));
      const latestGridPower = currentDateGridPower?.powerArray.sort((a, b) => b.timestamp.toDate() - a.timestamp.toDate())[0];
   
      if (latestGridPower) {
        entry.grid = Math.round(latestGridPower.activePower.L1 + latestGridPower.activePower.L2 + latestGridPower.activePower.L3);
      }
    }

    // Calculate consumption
    entry.consumption = entry.grid !== 0 ? Math.round(entry.solar + Math.abs(entry.grid)) : 0;

    // Return the most recent data
    return {
      solarData: entry.solar,
      batteryData: 0, // Static as per instructions
      gridData: entry.grid,
      consumptionData: entry.consumption
    };
  }

  return (
    <Box p={3} className="border rounded bg-white shadow h-100">
      <Typography variant='h5' sx={{ color: 'var(--primary)', marginBottom: '20px' }}>Opticaster</Typography>
      <Grid container spacing={0} className='w-100' style={{ minHeight: '250px' }}>

        <Grid item xs={12} container direction="column" justifyContent="center" alignItems="center" style={{ color: (solarData > -100 && solarData < 100) ? "gray" : "inherit" }}>
          {/* Solar Panel Data */}
          <Typography className='color-light' variant='caption' sx={{ marginBottom: "10px" }}>Solar</Typography>
          <Box
            className={styles.circleData}
            sx={{
              marginBottom: "10px",
              boxShadow: solarData < 100 ? "none" : "0 0 12px 4px rgb(205, 205, 0)",
              border: solarData > 100 ? "none" : "#808080 1px solid",
            }}
          >
            <SolarPowerIcon />
            {solarData > 100 && `${Math.abs(solarData / 1000).toFixed(1)} kW`}
          </Box>
          {/* Power Flow Icon */}
          {solarData > 100 && <DownIcon className={styles.blink} style={{ color: 'rgb(205, 205, 0)' }} />}
          {solarData < -100 && <UpIcon className={styles.blink} style={{ color: 'rgb(205, 205, 0)' }} />}
        </Grid>

        {/* Grid Data */}
        <Grid item xs={6} container direction="column" justifyContent="start" alignItems="center" style={{ color: (gridData > -100 && gridData < 100) ? "gray" : "inherit" }}>

          <Box display="flex" alignItems="center">
            <Box
              className={styles.circleData}
              sx={{
                marginRight: (gridData > 100 || gridData < -100) ? "10px" : "25px",
                boxShadow: gridData < 100 ? "none" : "0 0 12px 4px rgb(113, 113, 113)",
                border: gridData > 100 ? "none" : "#808080 1px solid",
              }}
            >
              <FactoryIcon />
              {(Math.abs(gridData) > 100) && `${Math.abs(gridData / 1000).toFixed(1)} kW`}
            </Box>

            {/* Power Flow Icons */}

            {(gridData > 100) && <RightIcon className={styles.blink} style={{ color: 'rgb(113, 113, 113)' }} />}
            {(gridData < -100) && <LeftIcon className={styles.blink} style={{ color: 'rgb(113, 113, 113)' }} />}

          </Box>

          {/* Typography at the bottom */}
          <Typography className='color-light' variant='caption' sx={{ marginTop: "10px", marginLeft: "-35px" }}>Grid</Typography>

        </Grid>

        {/* Consumption Data */}
        <Grid item xs={6} container direction="column" justifyContent="start" alignItems="center" style={{ color: (consumptionData > -100 && consumptionData < 100) ? "gray" : "inherit" }}>

          <Box display="flex" alignItems="center">

            {/* Power Flow Icons */}
            {(consumptionData > 100) && <RightIcon className={styles.blink} style={{ color: 'rgb(0, 172, 14)' }} />}
            {(consumptionData < -100) && <LeftIcon className={styles.blink} style={{ color: 'rgb(0, 172, 14)' }} />}

            <Box
              className={styles.circleData}
              sx={{
                marginLeft: (consumptionData > 100 || consumptionData < -100) ? "10px" : "25px",
                boxShadow: consumptionData < 100 ? "none" : "0 0 12px 4px rgb(0, 172, 14)",
                border: consumptionData > 100 ? "none" : "#808080 1px solid",
              }}
            >
              <GiteIcon />
              {(Math.abs(consumptionData) > 100) && `${Math.abs(consumptionData / 1000).toFixed(1)} kW`}
            </Box>

          </Box>

          {/* Typography at the bottom */}
          <Typography variant='caption' className='color-light' sx={{ marginTop: "10px", marginRight: "-35px" }}>Consumption</Typography>

        </Grid>


        <Grid item xs={12} container direction="column" justifyContent="center" alignItems="center" style={{ color: (batteryData > -100 && batteryData < 100) ? "gray" : "inherit" }}>
          {/* Battery Data */}
          {/* Power Flow Icon */}
          {batteryData > 0 && <UpIcon className={styles.blink} style={{ color: 'rgb(0, 78, 246)' }} />}
          {batteryData < 0 && <DownIcon className={styles.blink} style={{ color: 'rgb(0, 78, 246)' }} />}

          <Box
            className={styles.circleData}
            sx={{
              marginTop: "10px",
              boxShadow: batteryData === 0 ? "none" : "0 0 12px 4px rgb(0, 78, 246)",
              border: batteryData === 0 ? "#808080 1px solid" : "none",
            }}
          >
            <BatteryStdIcon />
            {batteryData !== 0 && `${Math.abs(batteryData / 1000).toFixed(1)} kW`}
          </Box>
          <Typography variant='caption' className='color-light' sx={{ marginTop: "10px" }}>Battery</Typography>
        </Grid>
      </Grid>

    </Box>
  );
}

export const WarrantyCard = ({ }) => {

  const {selectedSystemId, userSystems} = useSystemData();

  const [ selectedSystem, setSelectedSystem ] = useState(null);
  const [workmanshipExpiryDate, setWorkmanshipExpiryDate] = useState("N/A");
  const [solarPanelProductExpiryDate, setSolarPanelProductExpiryDate] = useState("N/A");
  const [solarPanelPerformanceExpiryDate, setSolarPanelPerformanceExpiryDate] = useState("N/A");
  const [inverterExpiryDate, setInverterExpiryDate] = useState("N/A");

  useEffect(() => {
    setSelectedSystem(userSystems.find(system => system.id === selectedSystemId));
  }, [selectedSystemId]);

  useEffect(() => {

    const installationArray = selectedSystem?.installation
    if (installationArray && installationArray.length > 0) {
      
      if (installationArray[0]?.workmanship_warranty) {
        setWorkmanshipExpiryDate(new Date(installationArray[0].workmanship_warranty.toDate()).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' }));
      }
      if (installationArray[0]?.solar?.product_warranty) {
        setSolarPanelProductExpiryDate(new Date(installationArray[0].solar.product_warranty.toDate()).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' }));
      }
      if (installationArray[0]?.solar?.performance_warranty) {
        setSolarPanelPerformanceExpiryDate(new Date(installationArray[0].solar.performance_warranty.toDate()).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' }));
      }
      if (installationArray[0]?.inverter?.product_warranty) {
        setInverterExpiryDate(new Date(installationArray[0].inverter.product_warranty.toDate()).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' }));
      }
    }
    
  }, [selectedSystem]);

  return (
    <Box p={3} className="border rounded bg-white shadow h-100">
      <Typography variant='h5' sx={{ color: 'var(--primary)', marginBottom: '20px' }}>Coverage</Typography>

      <Grid container sx={{ flexDirection: "column" }} spacing={1}>

        {/**Blueshfit Warranty */}
        <Grid item sx={{ flexGrow: "1" }}>
          <CardContent className='rounded bg-secondary'>
            {/*Item Name */}
            <Typography variant="body" color={"var(--primary);"}>
              {"Blueshift Warranty"}
            </Typography>
            {/*Warranty Descriptions */}
            <Box mt={2}>
              <Typography variant="body" color="text.secondary" >
                Workmanship Expiry: {workmanshipExpiryDate}
              </Typography>
            </Box>
          </CardContent>
        </Grid>

        {/**Solar Panels */}
        <Grid item sx={{ flexGrow: "1" }}>
          <CardContent className='rounded bg-secondary'>
            {/*Item Name */}
            <Box mb={2}>
              <Typography variant="body" color={"var(--primary);"}>
                {"Solar Panels"}
              </Typography>
            </Box>

            {/*Warranty Descriptions */}
            <Box>
              <Typography variant="body" color="text.secondary" >
                Product Warranty Expiry: {solarPanelProductExpiryDate}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body" color="text.secondary" >
                Performance Warranty Expiry: {solarPanelPerformanceExpiryDate}
              </Typography>
            </Box>
          </CardContent>
        </Grid>

        {/**Inverter Warranty */}
        <Grid item sx={{ flexGrow: "1" }}>
          <CardContent className='rounded bg-secondary'>
            {/*Item Name */}
            <Box mb={2}>
              <Typography variant="body" color={"var(--primary);"}>
                {"Inverter"}
              </Typography>
            </Box>

            {/*Warranty Descriptions */}
            <Box>
              <Typography variant="body" color="text.secondary" >
                Product Warranty Expiry: {inverterExpiryDate}
              </Typography>
            </Box>
          </CardContent>
        </Grid>

      </Grid>

    </Box>
  );
}

export const RequestServicesCard = ({ }) => {

  const expiryDate = "Dec 08 2023"

  return (

    <Box p={3} className="border rounded bg-white shadow h-100">
      <Typography variant='h5' sx={{ color: 'var(--primary)', marginBottom: '20px' }}>Services</Typography>

      <Grid container spacing={1} sx={{ display: 'flex', alignItems: 'stretch' }}>

        {/**Panel Cleaning */}
        <Grid item md={6} xs={12} sx={{ display: 'flex' }}>
          <CardContent className='rounded bg-secondary' sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            flexGrow: 1,
          }}>
            {/*Service Name */}
            <Typography variant="body" color={"var(--primary);"}>
              {"Request Cleaning"}
            </Typography>

            {/* Descriptions */}
            <Typography variant="body" color="text.secondary" >
              Get your panels cleaned by us.
            </Typography>

            {/** Service Stats */}
            <Box mt={2} sx={{
              display: 'flex',
              flexDirection: 'column',
              whiteSpace: "normal"
            }}>
              <Typography variant="body" color="text.secondary" >
                Price: P1,382
              </Typography>
              <Typography variant="body" color="text.secondary" >
                Duration: 1 Day
              </Typography>

            </Box>

            <Button variant='outlined' sx={{ marginTop: "25px" }}>Request</Button>
          </CardContent>
        </Grid>

        {/**System Check */}
        <Grid item md={6} xs={12} sx={{ display: 'flex' }}>
          <CardContent className='rounded bg-secondary' sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            flexGrow: 1,
          }}>
            {/*Service Name */}
            <Typography variant="body" color={"var(--primary);"}>
              Human System Check
            </Typography>

            {/* Descriptions */}
            <Typography variant="body" color="text.secondary" >
              Find something wrong? Have a human check.
            </Typography>

            {/** Service Stats */}
            <Box mt={2} sx={{
              display: 'flex',
              flexDirection: 'column',
              whiteSpace: "normal"
            }}>
              <Typography variant="body" color="text.secondary" >
                Price: Free
              </Typography>
              <Typography variant="body" color="text.secondary" >
                Duration: 2-3 Days
              </Typography>
            </Box>

            <Button variant='outlined' sx={{ marginTop: "25px" }}>Request</Button>
          </CardContent>
        </Grid>

        {/**Submit Feedback */}
        <Grid item md={6} xs={12} sx={{ display: 'flex' }}>
          <CardContent className='rounded bg-secondary' sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            flexGrow: 1,
          }}>
            {/*Service Name */}
            <Typography variant="body" color={"var(--primary);"}>
              Submit Feedback
            </Typography>

            {/* Descriptions */}
            <Typography variant="body" color="text.secondary" >
              Tell us what you like and don't like. Help us improve.
            </Typography>

            {/** Service Stats */}
            <Box mt={2} sx={{
              display: 'flex',
              flexDirection: 'column',
              whiteSpace: "normal"
            }}>
            </Box>

            <Button variant='outlined' sx={{ marginTop: "25px" }}>Submit</Button>
          </CardContent>
        </Grid>

      </Grid>

    </Box>


  );

}

const SingleDataCard = ({ label, value, unit, type = '', progressMax = 100, description = '' }) => {
  return (
    <div className='rounded bg-secondary' >
      <CardContent>
        <Typography variant="body2" color="text.secondary" className={styles.valueLabel}>
          {label}
        </Typography>
        <div className={styles.dataRow}>
          {type === 'progress' ? (
            <Box display="flex" alignItems="center" width="100%" minHeight="40px">
              <Typography variant="body1" style={{ color: "var(--primary)", marginRight: '10px' }}>
                {value.toFixed(2)}{unit}
              </Typography>
              <LinearProgress
                variant="buffer"
                valueBuffer={0}
                value={Math.round((100 / progressMax) * value)}
                style={{ height: '20px', borderRadius: '10px', width: '100%' }}
              />
            </Box>
          ) : (
            <Typography variant="h4" style={{ color: "var(--primary)" }} component="span" className={styles.valueData}>
              {value} {unit}
            </Typography>
          )}
          <Typography variant="body2" style={{ color: "gray" }} component="span" >
            {description}
          </Typography>
        </div>
      </CardContent>
    </div>
  );
};

const MultipleDataCard = ({ dataSet }) => {
  return (
    <div className='rounded bg-secondary'>
      <div className={styles.flexContainer}>
        {dataSet.map((data, index) => (
          <CardContent key={index} sx={{ flex: 1 }}>
            <Typography variant="body2" color="text.secondary" className={styles.valueLabel}>
              {data.label}
            </Typography>
            <div className={styles.dataRow}>
              <Typography variant="h5" color={"var(--primary);"} component="span" className={styles.valueData}>
                {data.value} {data.unit}
              </Typography>
            </div>
          </CardContent>
        ))}
      </div>
    </div>
  );
};



// Used in settings
export const SolarSystemInfoCard = ({ system }) => {
  return (
    <Card variant='outlined'>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" style={{ marginBottom: '16px' }}>
            {system.name}
          </Typography>
          <Typography variant="body2" color="text.secondary" component="div" style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <span><i className="icon-capacity"></i> Capacity: {system.capacity}</span>
            <span><i className="icon-location"></i> Location: {system.location}</span>
            <span><i className="icon-status"></i> Status: {system.status}</span>
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>

  );
};


